<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formUsuario" lazy-validation>
      <v-container fluid>
        <v-widget title="Informações do Emitente" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-tabs v-model="abaAtiva">
              <v-tab :key="1">Informações do Usuário</v-tab>
              <v-tab :key="2">Acesso às Empresas</v-tab>
            </v-tabs>

            <v-tabs-items v-model="abaAtiva">
              <v-divider></v-divider>

              <v-tab-item :key="1" eager>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-text-field label="Nome *" v-model="usuario.nome" :rules="validarNome" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="!estaEditando">
                      <v-text-field label="E-mail *" v-model="usuario.email" :rules="validarEmail" :disabled="$store.state.usuario_sistema.modoEdicao"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="!estaEditando">
                      <v-text-field type="password" label="Senha *" v-model="usuario.senha" :rules="validarSenha" :disabled="$store.state.usuario_sistema.modoEdicao"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="!estaEditando">
                      <v-text-field type="password" label="Confirmar Senha *" v-model="confirmacaoSenha" :disabled="$store.state.usuario_sistema.modoEdicao"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              
              <v-tab-item :key="2" eager>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-data-table v-model="empresasSelecionadas" show-select :headers="headers" :items="empresas" item-key="_id" :loading="carregando" hide-default-footer>
                        <template v-slot:item="props">
                          <tr>
                            <td><v-simple-checkbox :ripple="false" color="primary" :value="props.isSelected" @input="props.select($event)"></v-simple-checkbox></td>
                            <td class="text-no-wrap">{{ props.item.cnpj | formatarCnpj }}</td>
                            <td>{{ props.item.fantasia }}</td>
                            <td>
                              <v-chip chip color="primary">{{ constantes.TIPOS_MODO.find(modo => modo.value === props.item.plano).text }}</v-chip>
                            </td>
                            <td style="width: 30%">
                              <v-select label="Nível de Acesso" rounded single-line outlined full-width dense :items="constantes.NIVEIS_ACESSO" v-model="props.item.nivel_acesso" :disabled="!props.isSelected" primary hide-details></v-select>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">Cancelar</v-btn>  
            <v-btn color="primary" :loading="carregando" @click="salvar" :disabled="!valid">{{textoBtnConfirmar}}</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>  
  </div>
</template>


<script>

import {mapState, mapGetters, mapActions} from "vuex";
import constantes from '@/util/constantes';
import validador from '@/util/validador';

export default {

  data: () => ({

    valid: false,
    validarNome: [v => !!v || "Nome Obrigatório"],
    validarSenha: [v => !!v || "Senha Obrigatória"],
    validarEmail: [v => (v && validador.email(v)) || "E-mail Inválido"],
    confirmacaoSenha: '',
    constantes: constantes,
    abaAtiva: 0,
    loading: false,
    empresasSelecionadas: [],
    headers: [
      { text: 'CNPJ', value: 'cnpj', sortable: false },
      { text: 'Fantasia', value: 'fantasia', sortable: false },
      { text: 'Modo Utilização', value: 'plano', sortable: false },
      { text: 'Nível de Acesso *', value: 'nive_acesso', sortable: false, align: 'center' },
    ]
  }),

  computed: {

    ...mapState('usuario_sistema', {
      usuario: 'usuarioAtual',
      empresas: 'empresas',
      carregando: 'carregando'
    }),
    
    ...mapGetters('usuario_sistema', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar'
    })
  },

  methods: {

    ...mapActions('usuario_sistema', {
      carregarEmpresas: 'carregarEmpresas'
    }),
    
    async salvar() {

      if (this.$refs.formUsuario.validate()) {
        try{

          if(this.usuario.senha === this.confirmacaoSenha || this.estaEditando){
            if(this.empresasSelecionadas.length > 0){
              let nivelAcessoDefinido = this.empresasSelecionadas.filter(empresa => empresa.nivel_acesso === '').length === 0;
              if(nivelAcessoDefinido){
                await this.$store.dispatch('usuario_sistema/salvar', this.empresasSelecionadas);
                this.$router.push('/usuarios');
              }
              else{
                this.$store.commit('setMensagemErro', 'O nível de acesso não definido uma ou mais empresas');
              }
            }
            else{
              this.$store.commit('setMensagemErro', 'Nenhuma empresa selecionada');
            }
          }
          else{
            this.$store.commit('setMensagemErro', 'Senha e confirmação de senha estão diferentes');
          }
        }
        catch(erro){
          console.log('Houve um erro');
        }
      }
    },

    cancelar() {
      this.$store.commit('usuario_sistema/estadoInicial');
      this.$router.push({ name: 'usuarios' });
    },
  },

  async created(){

    try{

      this.loading = true;

      let usuario = this.$store.state.usuario_sistema.usuarioAtual;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if(estaEditando && (!usuario._id || usuario._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione um usuário para editar');
        this.$router.push('/usuarios');
      }

      if(!estaEditando){
        this.$store.commit('usuario_sistema/estadoInicial');
      }
      await this.carregarEmpresas();

      if(estaEditando){
        this.empresasSelecionadas = this.empresas.filter(empresa => empresa.nivel_acesso !== '');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>