<template>
  <div>

    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formEmpresa" lazy-validation>
      <v-container fluid>
        <v-widget title="Informações da Empresa" :temCampoObrigatorio="true">
          <div slot="widget-content">
            
            <v-tabs v-model="abaAtiva">
              <v-tab :key="1">Dados da Empresa</v-tab>
              <v-tab :key="2">NF-e</v-tab>
              <v-tab :key="3">Configurações Fiscais</v-tab>
              <v-tab :key="4">Usuário</v-tab>
            </v-tabs>

            <v-tabs-items v-model="abaAtiva">
              <v-divider></v-divider>
              
              <v-tab-item :key="1" eager>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field label="CNPJ *" v-model="empresa.cnpj" :loading="carregando" v-mask="'##.###.###/####-##'" :rules="validarCnpj" required :readonly="estaEditando || carregando"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Insc. Estadual *" v-model="empresa.ie" :rules="validarInscricao" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Insc. Municipal" v-model="empresa.im"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field label="Razão Social *" v-model="empresa.razao" required :rules="validadarRazaoNome"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field label="Nome Fantasia" v-model="empresa.fantasia"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <campo-cep v-mask="'#####-###'" label="CEP" buscarcep @resultado="setEndereco" v-model="empresa.cep" append-icon="map" data-cy="cep"></campo-cep>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field label="Logradouro *" v-model="empresa.logradouro" :rules="validarLogradouro"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Número *" v-model="empresa.numero" :rules="validarNumero"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Complemento" v-model="empresa.complemento"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Bairro *" v-model="empresa.bairro" :rules="validarBairro"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete label="Estado" v-model="empresa.estado" :items="constantes.ESTADOS_AUTOCOMPLETE" @change="filtrarCidadesEstado(empresa.estado)"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete label="Cidade *" v-model="empresa.codigo_cidade" @change="setCidade(empresa.codigo_cidade)" :items="cidades" :rules="validarCidade" :disabled="cidades.length == 0 || $store.state.empresa.carregando_cidade" :loading="$store.state.empresa.carregando_cidade"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="País *" v-model="empresa.pais" :rules="validarPais" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Telefone *" v-model="empresa.telefone" :rules="validarTelefone" v-mask="'(##)####-####'"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field label="E-Mail *" v-model="empresa.email" :rules="validarEmail"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item :key="2" eager>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" sm="2">
                      <v-select label="Versão NF-e" v-model="empresa.versao" :items="constantes.VERSAO_NFE"></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-select label="Ambiente *" v-model="empresa.ambiente" :items="constantes.AMBIENTES" :rules="validarAmbiente"></v-select>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-select label="Formato de Impressão" v-model="empresa.formato_impressao" :items="constantes.FORMATO_IMPRESSAO"></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field label="Série Padrão *" v-model="empresa.configuracao_tributaria.serie_padrao" v-mask="'###'" :rules="validarSerie" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-select label="Frete *" v-model="empresa.frete_padrao" :items="constantes.MODELO_FRETE" :rules="validarFretePadrao"></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-select label="Forma de Pgto. Padrão *" v-model="empresa.forma_pagamento_padrao" :items="constantes.FORMA_PAGAMENTO" :rules="validarFormaPgtoPadrao"></v-select>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-select label="Cácular Preço de Custo" v-model="empresa.calculo_custo" :items="constantes.CALCULOS_PRECO_CUSTO"></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item :key="3" eager>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field label="CNAE *" v-model="empresa.cnae" v-mask="'####-#/##'" @input="consultarCnae(empresa.cnae)" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field label="Descrição da Atividade Econômica" v-model="empresa.descricao_cnae" readonly required :rules="validarDescCnae"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select label="Ramo de Atividade *" v-model="empresa.configuracao_tributaria.ramo_atividade" :items="constantes.RAMO_ATIVIDADES" @change="definirCfopRamoAtividade" required :rules="validarRamoAtiv"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select label="Configuração CFOP *" @change="atualizarConfigCfop()" v-model="empresa.configuracao_tributaria.config_cfop_padrao" :items="constantes.CONFIG_CFOP_PADRAO"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="empresa.configuracao_tributaria.config_cfop_padrao !== '1'">
                      <v-text-field label="CFOP Padrão *" v-mask="'####'" v-model="empresa.configuracao_tributaria.cfop_padrao" :rules="validarCfopPadrao"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select label="Regime Tributário *" v-model="empresa.configuracao_tributaria.regime_tributario" :items="constantes.REGIMES_TRIBUTARIOS" required :rules="validarRegime"></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" v-if="ehSimplesNacional">
                      <v-select label="CSOSN *" :items="constantes.CSOSN" v-model="empresa.configuracao_tributaria.csosn" @change="definirInfoComplementar()" required :rules="validarCsosn"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="ehSimplesNacionalComPermissaoCredito">
                      <v-text-field label="Alíq. Créd. SN" v-model="empresa.configuracao_tributaria.aliq_cred_sn" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-textarea label="Informações Complementares" v-model="empresa.configuracao_tributaria.info_complementar" required></v-textarea>
                    </v-col>
                  </v-row>
                </v-container> 
              </v-tab-item>

              <v-tab-item :key="4" eager>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Nome" v-model="empresa.usuario.nome" :readonly="estaEditando"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field label="E-mail" v-model="empresa.usuario.email" :rules="validarEmail" :readonly="estaEditando"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

            </v-tabs-items>
          </div>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar()">{{constantes.BOTAO_CANCELAR}}</v-btn>  
            <v-btn color="primary" :loading="carregando" @click="salvar()" :disabled="!valid">{{textoBtnConfirmar}}</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>  
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import constantes from "@/util/constantes";
import validador from "@/util/validador";
import { mask } from 'vue-the-mask';

export default {

  directives: {
    mask
  },
  
  data: () => ({
    valid: false,
    validarSerie: [(v) => !!v || 'Série obrigatória', v => (v && v.length === 3) || 'Formato Inválido, ex: 001'],
    validarCnpj: [v => (v && v.length === 18 ? validador.cnpj(v) : false) || "CNPJ Inválido"],
    validarInscricao: [v => !!v || "Inscrição Obrigatória"],
    validadarRazaoNome: [v => !!v || "Razão/Nome Obrigatório"],
    validarCep: [v => !!v || "CEP Obrigatório"],
    validarLogradouro: [v => !!v || "Logradouro Obrigatório"],
    validarNumero: [v => !!v || "Número Obrigatório"],
    validarBairro: [v => !!v || "Bairro Obrigatório"],
    validarCidade: [v => !!v || "Cidade Obrigatória"],
    validarPais: [v => !!v || "País Obrigatório"],
    validarTelefone: [v => !!v || "Telefone Obrigatório"],
    validarAmbiente: [v => !!v || "Ambiente Obrigatório"],
    validarFretePadrao: [v => !!v || "Frete Padrão Obrigatório"],
    validarFormaPgtoPadrao: [v => !!v || "Frete Padrão Obrigatório"],
    validarTipoCertificado: [v => !!v || "Tipo de Cert. Obrigatório"],
    validarDescCnae: [v => !!v || "Informe um CNAE válido"],
    validarRamoAtiv: [v => !!v || "Ramo Obrigatório"],
    validarCfopPadrao: [v => !!v || "CFOP Padrão Obrigatório"],
    validarRegime: [v => !!v || "Regime Trib. Obrigatório"],
    validarCsosn: [v => !!v || "CSOSN Obrigatório"],
    validarEmail: [v => (v && validador.email(v)) || "E-mail Inválido"],
    validarNomeUsuario: [v => !!v || "Nome do usuário obrigatório"],
    constantes: constantes,
    cidadeSelecionada: {},
    abaAtiva: 0,
    loading: false
  }),

  computed: {
    ...mapState('empresa', {
      empresa: 'empresaAtual',
      carregando: 'carregando',
      certificadoCarregado: 'certificadoCarregado',
    }),
    ...mapGetters('empresa', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
      certificadoA1: 'possuiCertA1',
      cidades: 'listaCidades'
    }),

    ehSimplesNacional(){
      let configTributaria = this.$store.state.empresa.empresaAtual.configuracao_tributaria;
      if(configTributaria){
        if(configTributaria.regime_tributario === 1 || configTributaria.regime_tributario === 2){
          return true;
        }
        else{
          return false;
        }
      }
      else{
        return false;
      }
    },

    ehSimplesNacionalComPermissaoCredito(){
      let configTributaria = this.$store.state.empresa.empresaAtual.configuracao_tributaria;
      if(configTributaria){
        if((configTributaria.regime_tributario === 1 || configTributaria.regime_tributario === 2) && configTributaria.csosn == 101){
          return true;
        }
        else{
          return false;
        }
      }
      else{
        return false;
      }
    }
  },

  methods: {

    ...mapActions('empresa', {
      filtrarCidadesEstado: 'filtrarCidadesEstado'
    }),

    ...mapMutations('empresa', {
      setCidade: 'setCidade',
      atualizarConfigCfop: 'atualizarConfigCfop'
    }),

    definirCfopRamoAtividade(){

      let configTrib = this.empresa.configuracao_tributaria;
      if(configTrib.ramo_atividade == '1'){ //comércio
        configTrib.cfop_padrao = '5102';
      }
      else if(configTrib.ramo_atividade == '3'){ //indústria
        configTrib.cfop_padrao = '5101';
      }
    },

    async salvar() {
      if (this.$refs.formEmpresa.validate()) {
        try{
          
          let inscricao = `${this.empresa.ie}-${this.empresa.estado}`;
          let isInscricaoValida = validador.inscricaoEstadual(inscricao);
          if(isInscricaoValida){
            await this.$store.dispatch("empresa/salvar");
            this.$router.push('/empresasAdmin');
          }
          else{
            this.$store.commit('setMensagemErro', `Inscrição ${inscricao} inválida`);
          }
        }
        catch(erro){
          console.log('Houve um erro');
        }
      }
    },

    cancelar() {
      this.$router.push('/empresasAdmin');
    },

    setEndereco(endereco) {
      this.$store.commit('empresa/setEndereco', endereco);
      this.filtrarCidadesEstado(this.empresa.estado);
    },

    consultarCnae(cnae) {
      if (cnae.length === 9) {
        let cnaeConsulta = cnae.replace('-', '').replace('/', '');
        this.$store.dispatch("empresa/consultarCnae", cnaeConsulta);
      }
    },

    preencherEmailPadrao(){
      this.abaUsuario = true;
      this.$store.commit('empresa/preencherEmailPadrao');
    },

    definirInfoComplementar(){
      let configTributaria = this.$store.state.empresa.empresaAtual.configuracao_tributaria;
      if(configTributaria){
        if(configTributaria.regime_tributario == 1 || configTributaria.regime_tributario == 2){
          if(configTributaria.csosn == 101){
            let texto = "DOCUMENTO EMITIDO POR ME OU EPP OPTANTE PELO SIMPLES NACIONAL, " 
                      + "NÃO GERA DIREITO A CRÉDITO FISCAL DE IPI " 
                      + "PERMITE O APROVEITAMENTO DO CRÉDITO DE ICMS CORRESPONDENTE À ALÍQUOTA DE " + configTributaria.aliq_cred_sn + "% "
                      + "NOS TERMOS DO ART. 23 DA LEI COMPLEMENTAR Nº 123, DE 2006";
            this.$store.commit('empresa/setInfoComplementar', texto);
          }
          else if(configTributaria.csosn == 102){
            let texto = "DOCUMENTO EMITIDO POR ME OU EPP OPTANTE PELO SIMPLES NACIONAL"
                      + "NÃO GERA DIREITO A CRÉDITO FISCAL DE IPI";
            this.$store.commit('empresa/setInfoComplementar', texto);
          }
          else{
            this.$store.commit('empresa/setInfoComplementar', '');  
          }
        }
        else{
          this.$store.commit('empresa/setInfoComplementar', '');
        }
      }
      else{
        this.$store.commit('empresa/setInfoComplementar', '');
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit('empresa/setCidades', []);
    next();
  },

  async created(){
    
    try{

      this.loading = true;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
  
      if(estaEditando && (!this.empresa._id || this.empresa._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione uma empresa para editar');
        this.$router.push('/empresasAdmin');
      }
      else if(estaEditando){
        if(this.empresa.estado)
          await this.filtrarCidadesEstado(this.empresa.estado);
      }

      if(!estaEditando){
        this.$store.commit('empresa/estadoInicial');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>